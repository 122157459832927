import React, { Component, createRef } from 'react'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'

const edits = [
	{
		link: `http://cloud.blessedx.space/files/79b6cbfc-a917-4ae8-a538-4d3279ad9f47.mp4`,
		color: `blue`,
		style: {
			content: {
				teamName: {
					color: '#05D8FF',
				},
			},
			block: {
				main: {
					border: '2px #05D8FF solid',
				},
				cardSubmit: {
					border: '1px #05D8FF solid',
					backgroundColor: '#05D8FF',
				},
				cardTitle: {
					color: '#05D8FF',
				},
				cardDescription: {
					color: '#05D8FF',
				},
			},
		},
	},
	{
		link: 'http://cloud.blessedx.space/files/7b245d01-0922-4573-869d-43e61c848e01.mp4',
		color: 'pink',
		style: {
			content: {
				teamName: {
					color: '#EC20A6',
				},
			},
			block: {
				main: {
					border: '#EC20A6 solid',
				},
				cardSubmit: {
					border: '#EC20A6 solid',
					backgroundColor: '#EC20A6',
				},
				cardTitle: {
					color: '#EC20A6',
				},
				cardDescription: {
					color: '#EC20A6',
				},
			},
		},
	},
	{
		link: 'http://cloud.blessedx.space/files/e55101cd-e8d6-4a93-a723-39c15f13d33a.mp4',
		color: 'red',
		style: {
			content: {
				teamName: {
					color: '#D8294B',
				},
			},
			block: {
				main: {
					border: '#D8294B solid',
				},
				cardSubmit: {
					border: '#D8294B solid',
					backgroundColor: '#D8294B',
				},
				cardTitle: {
					color: '#D8294B',
				},
				cardDescription: {
					color: '#D8294B',
				},
			},
		},
	},
	{
		link: 'http://cloud.blessedx.space/files/0c9cfbb2-e3db-4e49-a339-c128614a35a0.mp4',
		color: 'pink',
		style: {
			content: {
				teamName: {
					color: '#F8998F',
				},
			},
			block: {
				main: {
					border: '#F8998F solid',
				},
				cardSubmit: {
					border: '#F8998F solid',
					backgroundColor: '#F8998F',
					color: '#000',
				},
				cardTitle: {
					color: '#F8998F',
				},
				cardDescription: {
					color: '#F8998F',
				},
			},
		},
		isChanged: true,
		changeTime: state => {
			setTimeout(() => {
				state.setState({
					styles: {
						content: {
							teamName: {
								color: '#FFA3B5',
							},
						},
						block: {
							main: {
								border: '#FFA3B5 solid',
							},
							cardSubmit: {
								border: '#FFA3B5 solid',
								backgroundColor: '#FFA3B5',
								color: '#000',
							},
							cardTitle: {
								color: '#FFA3B5',
							},
							cardDescription: {
								color: '#FFA3B5',
							},
						},
					},
				})
			}, 28000)
		},
	},
	{
		link: 'http://cloud.blessedx.space/files/6b40ba6c-17ed-4e29-ab5f-c43a9c092b65.mp4',
		color: 'lite_gray',
		style: {
			content: {
				teamName: {
					color: '#EBDFE4',
				},
			},
			block: {
				main: {
					border: '#EBDFE4 solid',
				},
				cardSubmit: {
					border: '#EBDFE4 solid',
					backgroundColor: '#EBDFE4',
					color: '#000',
				},
				cardTitle: {
					color: '#EBDFE4',
				},
				cardDescription: {
					color: '#EBDFE4',
				},
			},
		},
	},
]

class Example extends Component {
	constructor(props) {
		super(props)

		this.state = {
			videoURL: edits[0].link,
			volume: 0.05,
			styles: {
				content: {
					teamName: {
						color: '#ffffff',
					},
				},
				block: {
					main: {
						border: '#ffffff solid',
					},
					cardSubmit: {
						border: '#000 solid',
						backgroundColor: '#000',
					},
					cardTitle: {
						color: '#fff',
					},
					cardDescription: {
						color: '#fff',
					},
				},
			},
			display: {
				opacity: 1,
			},
			muted: true,
			checkAMV: false,
			amvName: 'Открыть эдит',
			audioName: 'Включить',
		}

		this.randomizeEdit = this.randomizeEdit.bind(this)
		this.openAmv = this.openAmv.bind(this)
		this.editMuted = this.editMuted.bind(this)
	}

	componentDidMount() {
		this.randomizeEdit()
	}

	setStyles(style) {
		this.setState({ styles: style })
	}

	randomizeEdit() {
		let videoURL = edits[Math.floor(Math.random() * edits.length)]

		this.setState({ videoURL: videoURL.link })

		this.setStyles(videoURL.style)

		if (videoURL?.isChanged) videoURL.changeTime(this)

		const video = document.getElementById('background-video')

		if (video) {
			video.volume = this.state.volume
			video.play()
		} //
	}

	openAmv() {
		if (this.state.display.opacity == 0) {
			this.setState({
				amvName: 'Открыть эдит',
				display: {
					opacity: 1,
				},
			})
		} else {
			this.setState({
				amvName: 'Закрыть эдит',
				display: {
					opacity: 0,
				},
			})
		}
	}

	editMuted() {
		if (this.state.muted) {
			this.setState({
				muted: false,
				audioName: 'Выключить',
			})
		} else {
			this.setState({
				muted: true,
				audioName: 'Включить',
			})
		}
	}

	render() {
		return (
			<div className='main'>
				{/* {!this.state.checkAMV ? (
					<>
						<div
							style={this.state.display}
							id="overlay"
							className="overlay"
						></div>
					</>
				) : (
					<></>
				)} */}

				<video
					src={this.state.videoURL}
					id='background-video'
					autoPlay
					onEnded={this.randomizeEdit}
					muted={this.state.muted}
				>
					<source src={this.state.videoURL} type='video/mp4' />
					<source src={this.state.videoURL} type='video/ogg' />
					Your browser does not support the video tag.
				</video>

				<div style={this.state.display} id='content' className='content'>
					<div className='team'>
						{/* <img
									className='team-icon'
									src='https://cloud.blessedx.space/files/8396fc97-59fe-4e31-a71b-75a1d7225eeb.png'
								></img> */}
						<h1
							style={this.state.styles.content.teamName}
							className='team-name'
						>
							Blessedx Utils
						</h1>
					</div>

					<div className='container'>
						<div style={this.state.styles.block.main} className='block'>
							{/* <img src='https://cloud.blessedx.space/files/c1392015-fdcc-4f62-a104-c1acd11ecbf7.png' /> */}
							<h2
								style={this.state.styles.block.cardTitle}
								className='card-title'
							>
								Discord Embed Builder
							</h2>
							<h4
								style={this.state.styles.block.cardDescription}
								className='card-description'
							>
								Билдер эмбедов для дискорда <br /> с возможностью сохранения
								эмбедов.
							</h4>
							<form action='https://builder.blessedx.space'>
								<button
									style={this.state.styles.block.cardSubmit}
									className='card-submit'
								>
									Перейти
								</button>
							</form>
						</div>
						<div style={this.state.styles.block.main} className='block'>
							{/* <img src='https://cloud.blessedx.space/files/f0a5be05-3685-4c5b-8594-ac0ed54371f2.png' /> */}
							<h2
								style={this.state.styles.block.cardTitle}
								className='card-title'
							>
								Discord Invite Checker
							</h2>
							<h4
								style={this.state.styles.block.cardDescription}
								className='card-description'
							>
								Чекер инвайтов дискорда полезно для <br /> отслеживания
								владельца ссылки.
							</h4>
							<form action='https://builder.blessedx.space'>
								<button
									style={this.state.styles.block.cardSubmit}
									className='card-submit'
								>
									Перейти
								</button>
							</form>
						</div>
						<div style={this.state.styles.block.main} className='block'>
							{/* <img src='https://cloud.blessedx.space/files/c1392015-fdcc-4f62-a104-c1acd11ecbf7.png' /> */}
							<h2
								style={this.state.styles.block.cardTitle}
								className='card-title'
							>
								QR Code Generator
							</h2>
							<h4
								style={this.state.styles.block.cardDescription}
								className='card-description'
							>
								Создаёт QR код из ссылки любого <br /> текста иного текстового
								конента.
							</h4>
							<form action='https://builder.blessedx.space'>
								<button
									style={this.state.styles.block.cardSubmit}
									className='card-submit'
								>
									Перейти
								</button>
							</form>
						</div>
						<div style={this.state.styles.block.main} className='block'>
							{/* <img src='https://cloud.blessedx.space/files/4195d93f-73d9-4248-9b9f-3941a37fc6ca.png' /> */}
							<h2
								style={this.state.styles.block.cardTitle}
								className='card-title'
							>
								Link Shortener
							</h2>
							<h4
								style={this.state.styles.block.cardDescription}
								className='card-description'
							>
								Генерирует сокращённые ссылки <br /> из заданной вами ссылки на
								сайт.
							</h4>
							<form action='https://shorten.blessedx.space'>
								<button
									style={this.state.styles.block.cardSubmit}
									className='card-submit'
								>
									Перейти
								</button>
							</form>
						</div>
					</div>
				</div>

				<div className='footer'>
					<button
						style={this.state.styles.block.cardSubmit}
						className='play-amv-btn'
						onClick={this.openAmv}
					>
						{this.state.amvName}
					</button>
					<button
						style={this.state.styles.block.cardSubmit}
						className='play-amv-btn-2'
						onClick={this.randomizeEdit}
					>
						Следующий эдит
					</button>{' '}
					<button
						style={this.state.styles.block.cardSubmit}
						className='play-amv-btn-3'
						onClick={this.editMuted}
					>
						{this.state.audioName} звук
					</button>
				</div>
			</div>
		)
	}
}

export default Example
